.App {
  text-align: center;
  height: 100%;
  width: 100%;
  margin: 0 auto;
  overflow-y: auto;
}

@media (min-aspect-ratio: 1/1) {
  .App {
    width: 80vh;
  }
}

.header {
  color: white;
  font-size: x-large;
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 0 0.5rem;
}

.header > p {
  margin: 0;
}

.ant-divider {
  margin: 0.5rem 0;
}
